const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const apiLoad = async (timeout = 100) => sleep(timeout);

const mocks = {
  Query: () => ({
    loginCode: async () => {
      await apiLoad(1000);
      return {
        __typename: "LoginCode",
        id: "123",
      };
    },
  }),

  Mutation: () => ({
    signUp: async (_, { fullName, workEmail }) => {
      await apiLoad(2000);
      return {
        __typename: "SignUpResult",
        workspaceID: "w123",
        prospectID: "abc",
        successful: true,
        errors: [],
      };
    },
    saveTrialPersonalization: async (
      _,
      { id, businessName, industry, purposes },
    ) => {
      await apiLoad(2000);
      return {
        __typename: "TrialPersonalizationResult",
        id: "123",
        successful: true,
        errors: [],
      };
    },
  }),
};

export default mocks;
