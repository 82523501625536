import { createClient } from "urql";
import { makeExecutableSchema, addMockFunctionsToSchema } from "graphql-tools";
import gql from "graphql-tag";
import mocks from "./mocks";

const typeDefs = gql`
  type LoginCode {
    id: ID!
  }

  type SignupProspectResult {
    prospectId: String!
    workspaceId: String!
  }

  type Query {
    loginCode: LoginCode
  }

  type MutationError {
    attribute: String
    fullMessage: String
    type: String!
  }

  type SignUpPayload {
    result: SignupProspectResult
    errors: [MutationError]!
  }

  type SignUpInput {
    fullName: String!
    workEmail: String!
    businessName: String!
    phoneNumber: String!
    timezone: String!
  }

  type SaveTrialPersonalizationInput {
    prospectId: ID!
    workspaceId: ID!
    industry: String!
    companySize: String!
  }

  type SaveTrialPersonalizationPayload {
    result: String
    errors: [MutationError]!
  }

  type Mutation {
    signUp(input: SignUpInput!): SignUpPayload!
    saveTrialPersonalization(
      input: SaveTrialPersonalizationInput!
    ): SaveTrialPersonalizationPayload!
  }
`;

const schema = makeExecutableSchema({ typeDefs: typeDefs });
addMockFunctionsToSchema({
  schema,
  mocks: mocks,
});

const client = createClient({
  url: `${process.env.API_URL}`,
  fetchOptions: {
    mode: "cors", // no-cors, cors, *same-origin
  },
  // exchanges: [schemaExchange(schema)]
});

export { client };
