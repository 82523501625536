import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";
import { isIOS } from "../../utils/getOS";

import imgAS from "../../assets/image/App_Store_Badge.svg";
import imgGP from "../../assets/image/Google_Play_Store_badge_EN.svg";
import installAppQrCode from "../../assets/image/install_app_qr_code.png";
import hipaaLogo from "../../assets/image/hipaa-logo.webp";

const Footer = () => {
  const gContext = useContext(GlobalContext);

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "text-storm gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const copyClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color-opacity"
      : "gr-text-color";

  return (
    <>
      <div
        className={`footer-section pt-15 pt-lg-25 pb-lg-21 ${
          gContext.footer.theme === "dark"
            ? "dark-mode-texts bg-blackish-blue"
            : ""
        }`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="3" md="4" className="text-center">
              <Logo
                white={gContext.footer.theme === "dark"}
                className="footer-logo mb-11"
              />
              <div className="download-btns">
                <p className="mb-1">
                  <a href="https://apps.apple.com/app/apple-store/id1577132228?pt=122904235&ct=landing_page&mt=8">
                    <img
                      src={imgAS}
                      alt="Apple AppStore Download"
                      className="mb-2 w-50"
                    />
                  </a>
                </p>

                {// Hide this button to pass appstore review
                // Because this page can be acccessed in Product Guide
                isIOS ? null : (
                  <p>
                    <a href="https://play.google.com/store/apps/details?id=com.camelo">
                      <img
                        src={imgGP}
                        alt="Google PlayStore Download"
                        className="w-50"
                      />
                    </a>
                  </p>
                )}
                <p className="mb-1">
                  <img
                    className="w-55 gr-image-box-shadow"
                    src={installAppQrCode}
                    alt="Install app QR code"
                  />
                </p>
              </div>

              <div className="badges-logo mt-11">
                <p className="mb-1">
                  <img
                    className="w-55"
                    src={hipaaLogo}
                    alt="HIPAA compliance"
                  />
                </p>
              </div>
            </Col>
            <Col lg="8" md="8" className="offset-lg-1">
              <Row>
                <Col xs="12" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Company</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/about" className={linkClassName}>
                          About Us
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/contact" className={linkClassName}>
                          Contact Us
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/before-after" className={linkClassName}>
                          Before and After
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/customers" className={linkClassName}>
                          Customer Stories
                        </Link>
                      </li>
                      {/* <li className="py-2"> */}
                      {/*   <Link to="/" className={linkClassName}> */}
                      {/*     Contact Support */}
                      {/*   </Link> */}
                      {/* </li> */}
                      <li className="py-2">
                        <Link to="/legal" className={linkClassName}>
                          Privacy &amp; Terms
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="https://better.camelohq.com"
                          className={linkClassName}
                        >
                          Feedback
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="https://status.camelohq.com"
                          className={linkClassName}
                        >
                          Status
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="12" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Product</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/why-camelo" className={linkClassName}>
                          Why Camelo?
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/features" className={linkClassName}>
                          Product Overview
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="/features/scheduling-software"
                          className={linkClassName}
                        >
                          Employee Scheduling
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="/features/time-and-attendance"
                          className={linkClassName}
                        >
                          Employee Time Clock
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          to="/features/workplace-communication"
                          className={linkClassName}
                        >
                          Team Messaging
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="12" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Resources</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <a
                          href="https://blog.camelohq.com"
                          className={linkClassName}
                        >
                          Blog
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          href="https://help.camelohq.com"
                          className={linkClassName}
                        >
                          Help Center
                        </a>
                      </li>
                      <li className="py-2">
                        <Link to="/templates" className={linkClassName}>
                          Templates
                        </Link>
                      </li>
                      {/* <li className="py-2"> */}
                      {/*   <Link to="/" className={linkClassName}> */}
                      {/*     Excel Scheduling Template */}
                      {/*   </Link> */}
                      {/* </li> */}
                      {/* <li className="py-2"> */}
                      {/*   <Link to="/" className={linkClassName}> */}
                      {/*     Excel Timesheet Template */}
                      {/*   </Link> */}
                      {/* </li> */}
                      {/* <li className="py-2"> */}
                      {/*   <Link to="/" className={linkClassName}> */}
                      {/*     Timecard Calculator */}
                      {/*   </Link> */}
                      {/* </li> */}
                      {/* <li className="py-2"> */}
                      {/*   <Link to="/" className={linkClassName}> */}
                      {/*     HR Toolkit */}
                      {/*   </Link> */}
                      {/* </li> */}
                      {/* <li className="py-2"> */}
                      {/*   <Link to="/" className={linkClassName}> */}
                      {/*     Employee of the Month Certificate */}
                      {/*   </Link> */}
                      {/* </li> */}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row className="border-top border-gray-3 pt-8">
                <Col xs="12" lg="12">
                  <Row>
                    <Col xs="12" lg="12">
                      <p className="footer-title gr-text-11 mb-7">
                        Industries served
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="4">
                      <ul className="footer-list list-unstyled gr-text-9">
                        <li className="py-2">
                          <Link
                            to="/industries/agriculture"
                            className={linkClassName}
                          >
                            Agriculture
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/barbershops"
                            className={linkClassName}
                          >
                            Barber Shops
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/call-centers"
                            className={linkClassName}
                          >
                            Call Centers
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/caregiving"
                            className={linkClassName}
                          >
                            Caregiving
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/charity"
                            className={linkClassName}
                          >
                            Charity
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/cleaning-services"
                            className={linkClassName}
                          >
                            Cleaning Services
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/construction"
                            className={linkClassName}
                          >
                            Construction
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/education"
                            className={linkClassName}
                          >
                            Education
                          </Link>
                        </li>
                      </ul>
                    </Col>
                    <Col xs="12" lg="4">
                      <ul className="footer-list list-unstyled gr-text-9">
                        <li className="py-2">
                          <Link
                            to="/industries/emergency"
                            className={linkClassName}
                          >
                            Emergency
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/entertainment"
                            className={linkClassName}
                          >
                            Entertainment
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/healthcare"
                            className={linkClassName}
                          >
                            Healthcare
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/hospitality"
                            className={linkClassName}
                          >
                            Hospitality
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/hotels-resorts"
                            className={linkClassName}
                          >
                            Hotels & Resorts
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/manufacturing-logistics"
                            className={linkClassName}
                          >
                            Manufacturing & Logistics
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/nonprofits"
                            className={linkClassName}
                          >
                            Nonprofits
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/nail-salons"
                            className={linkClassName}
                          >
                            Nail Salons
                          </Link>
                        </li>
                      </ul>
                    </Col>
                    <Col xs="12" lg="4">
                      <ul className="footer-list list-unstyled gr-text-9">
                        <li className="py-2">
                          <Link
                            to="/industries/professional-services"
                            className={linkClassName}
                          >
                            Professional Services
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/remote-work"
                            className={linkClassName}
                          >
                            Remote Work
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/restaurants-bars-coffee"
                            className={linkClassName}
                          >
                            Restaurants / Bars / Coffee Shops
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/retail"
                            className={linkClassName}
                          >
                            Retail
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/security"
                            className={linkClassName}
                          >
                            Security
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/beauty-spas"
                            className={linkClassName}
                          >
                            Spas
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/supermarkets"
                            className={linkClassName}
                          >
                            Supermarkets
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link
                            to="/industries/transportation"
                            className={linkClassName}
                          >
                            Transportation
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="copyright-area border-top py-9 my-12">
            <Row className="align-items-start">
              <Col lg="6">
                <div
                  className={`copyright-text gr-text-11 mb-6 mb-lg-0 text-center text-lg-left ${copyClassName}`}
                >
                  <div>
                    © {new Date().getFullYear()} Camelo LLC. All Right Reserved
                  </div>
                  <div>
                    <small>1209 Orange Street, Wilmington DE, 19801, USA</small>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="text-center text-lg-right">
                <ul className="social-icons list-unstyled mb-0">
                  <li className="ml-7">
                    <Link
                      to="https://twitter.com/camelohq"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-twitter"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link
                      to="https://www.facebook.com/camelohq"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-facebook"></i>
                    </Link>
                  </li>
                  {/* <li className="ml-7"> */}
                  {/*   <Link to="/" className={iconClassName}> */}
                  {/*     <i className="icon icon-instant-camera-2"></i> */}
                  {/*   </Link> */}
                  {/* </li> */}
                  <li className="ml-7">
                    <Link
                      to="https://www.linkedin.com/company/camelohq/"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col className="text-center text-muted pt-10">
                <p className="text-justify gr-text-12">
                  Camelo works great for all hourly teams and businesses,
                  including salons, spas, babershops, restaurants, coffee shops,
                  construction, caregiving, cleaning services, manufactoring,
                  retail, healthcare, professional services, and beyond. With
                  our{" "}
                  <a href="/scheduling-software">free employee scheduling</a>,{" "}
                  <a href="/time-and-attendance">time clocks, timesheets</a>,
                  and <a href="/workplace-communication">team communication</a>,
                  managers and staff can save time for more important tasks.
                  Check out our <a href="/features">product tour</a>, our{" "}
                  <a href="/before-after">customer stories</a>, or read our{" "}
                  <a href="https://blog.camelohq.com/">blog</a> to learn more
                  about us.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="text-center text-muted gr-opacity-8 pt-10">
                <em>
                  ~ Enjoy the rest of your{" "}
                  {new Date().toLocaleString("default", { weekday: "long" })}! ~
                </em>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
